<template>
    <div v-if="isLoggedIn" class="container page">


        <!--
    **************************************************************************************************************************************************
                                                                N E W     A D H O C
    **************************************************************************************************************************************************
    -->


        <b-alert v-if="!isManager" show>Only a manager may access this module.</b-alert>


        <section v-if="displayPart==0 && isManager" class="card card-default">
            <div class="card-header">
                <h5>Adhoc Recognition</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6"><strong>Recipient:</strong></div>
                    <div class="col-sm-6 text-right"></div>
                </div>
               
                <hr />
                <b-form @submit.prevent="onSubmitAdhoc" novalidate>
                    <b-form-group id="groupReason" label-for="selectreason" label="Value:" :invalid-feedback="fAdhoc.errors.reason" description="">
                        <b-form-select id="selectreason" v-model="fAdhoc.form.reason" :state="fAdhoc.state.reason" :options="valueOptions"></b-form-select>
                    </b-form-group>

                    <b-form-group id="groupPoints" label-for="selectPoints" label="Points Amount:" :invalid-feedback="fAdhoc.errors.points" description="" :state="fAdhoc.state.points">
                        <b-form-select id="selectPoints" v-model="fAdhoc.form.points" :state="fAdhoc.state.points" :options="pointsOptions"></b-form-select>
                    </b-form-group>


                    <b-form-group id="groupSendBy" label-for="sendBy" description="" :invalid-feedback="fAdhoc.errors.sendBy" :state="fAdhoc.state.sendBy" class="mt-4">
                        <b-form-radio-group id="radiogroupSendBy" v-model="fAdhoc.form.sendBy" name="sendBy" :state="fAdhoc.state.sendBy">
                            <div class="row">
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="email">Email</b-form-radio>
                                </div>
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="sms">SMS</b-form-radio>
                                </div>
                            </div>
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group id="groupAdhocMessage" label-for="txtAdhocMessage" label="Message to Recipients" :invalid-feedback="fAdhoc.errors.message" :description="(isSendBySMS?150:500)-fAdhoc.form.message.length + ' characters remaining'">
                        <b-form-textarea id="txtAdhocMessage" :disabled="fAdhoc.form.sendBy===null" v-model="fAdhoc.form.message" :state="fAdhoc.state.message" :placeholder="isSendBySMS ? 'Short SMS message to recipients up to 150 characters' : 'Short email message to recipients up to 500 characters'" rows="3" max-rows="5" :maxlength="(isSendBySMS?150:500)" />
                    </b-form-group>

                    <hr />

                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </b-form>
            </div>
        </section>

        <section v-if="displayPart==1" class="card card-default">
            <div class="card-header">
                <h5>Adhoc Recognition</h5>
            </div>
            <div class="card-body">
                <b-alert variant="success" show>
                    <h4 class="alert-heading">Adhoc Recognition</h4>
                    <p>
                        Successfully submitted Adhoc Recognition.
                    </p>
                </b-alert>
            </div>
            <div class="card-footer">
                <div class="text-right"><button type="button" @click="displayPart=0" class="btn btn-primary">Send Another</button></div>
            </div>
        </section>



        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Nominees -->
        <b-modal id="modalChooseNominees" title="ADHOC RECOGNITION REWARD" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Select recipients"
                           selectedTitle="Recipients"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedMems"
                           :isAllowWholeDivision="false"
                           :isSingle="false"
                           :showContinue="false"
                           class="mb-3" />

            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>
        </b-modal>

    </div>

</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';


    export default {
        name: 'GroupCEOaward',
        components: { MemberSelect },
        data() {
            return {
                displayPart: 0,

                fAdhoc: {
					form: { what:'specialadhoc', reason: null, points: null, message: '', sendBy: null, recipients: [] }
                },

                selectedMems: [],

                roles: { ca: false, cc: false, idea: false, inv: false },

                reportTitle: '',

            }
        },
        computed: {
			pointsOptions() {
				const pointsList = [5, 10, 20, 25, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 1000, 2000, 3000, 4000, 5000];
				let p = [{ text: '--- Select Amount to Award---', value: null, disabled: true }];
				pointsList.forEach(n => p.push({ text: n + '  e-points', value: n }));
				return p;
			},
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
			isManager() {
				return this.$store.state.memberStore.member.isManager
			},
            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isSendBySMS() {
                return this.fAdhoc.form.sendBy === 'sms';
            },
			valueOptions() {
                //const santamOptions = [
                //	{ html: '<b>--- Santam Options ---</b>', value: null, disabled: true },
                //	{ text: 'EXCELLENCE', value: 'EXCELLENCE' },
                //	{ text: 'HUMANITY', value: 'HUMANITY' },
                //	{ text: 'INTEGRITY', value: 'INTEGRITY' },
                //	{ text: 'INNOVATION', value: 'INNOVATION' },
                //	{ text: 'PASSION', value: 'PASSION' },
                //	{ html: '<b>--- Group Options ---</b>', disabled: true }
                //];

                const groupOptions = [
                    { text: 'Care', value: 'Care' },
                    { text: 'Collaboration', value: 'Collaboration' },
                    { text: 'Integrity', value: 'Integrity' },
                    { text: 'Innovation', value: 'Innovation' },
                ];
                //return this.memberClusterCode === 'stm' ? [...santamOptions, ...groupOptions] : [{ text: '--- Select an Option ---', value: null }, ...groupOptions];
                return [{ text: '--- Select an Option ---', value: null }, ...groupOptions];
			},
        },
        created() {
            //formSetState must be called on creation, to ensure all fields are setup
            this.formSetState(this.fAdhoc, true, true) //formObj, resetState, resetForm

            this.$store
                .dispatch('getDivisionList', {}) //To retrieve Country and Division lists
                .then(() => {
                    this.displayPart = 0;

                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error)));
                });


        },
        mounted() {

        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

            onSubmitAdhoc() {
                this.formSetState(this.fAdhoc, true);//formObj, resetState, resetForm
                this.fAdhoc.form.recipients = [];//this.selectedMems.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId);

                //this.displayPart = 1;
                if (this.displayPart == 0) {
                    return axios
                        .post(
                            '/api/reward/doreward',
                            this.fAdhoc.form
                        )
                        .then(({ data }) => {
                            console.log(data);
                            this.displayPart = 1;
                            this.selectedMems = [];
                            this.formSetState(this.fAdhoc, true, true);
							this.$store.dispatch('memberStore/getMemberData');
                            //this.msgBox('Successfully submitted nomination');
                        })
                        .catch((error) => {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occured: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fAdhoc.errors = error.response.data.formErrors;
                                this.formSetState(this.fAdhoc);
                            }
                        })
                        ;
                }
            },

        }




    }
</script>
